<template>
    <div class="container">
        <div class="success" v-if="status === 'success'">
            <div class="icon"><img src="../../assets/images/icon-success.png" alt="成功" /></div>
            <div class="status">授权成功</div>
        </div>
        <div class="fail" v-if="status === 'fail'">
            <div class="icon"><img src="../../assets/images/icon-fail.png" alt="成功" /></div>
            <div class="status">授权失败</div>
            <div class="desc" v-if="reason">
                <p>原因：{{reason}}</p>
                <p>操作建议：新增门店重新进行授权或者编辑门店地址重新进行授权</p>
            </div>
        </div>

        <div class="goApp">
            <div class="mpcode">
                <img src="https://onlineavatar.xingqiuxiuchang.com/mpshop/puhuizhanggui.mp.code.jpg" alt="普惠掌柜">
            </div>
            <p>长按识别小程序码进入小程序</p>
            <!-- <wx-open-launch-weapp style="width: 100%;" id="launch-btn" appid="wx2da7d1be8aeafc6a"
                path="pages/pigearAuth/index">
                <div class="btn">返回小程序</div>
            </wx-open-launch-weapp> -->
        </div>

    </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from "vue";
import { http } from "@/http";
import { Toast } from "vant";
import { useRoute, useRouter } from "vue-router";
import sha1 from 'sha1';

const $route = useRoute();

const status = ref('');
const reason = ref('');


onMounted(()=>{
    getToken();
    // getSignature();
});

const getToken = ()=>{
    let params = {
        code: $route.query.code,
        state: $route.query.state
    };
	http.post("/elm/callback/authToken", params).then((res) => {
        addShopOrderSourceRef(res.result);
	}).catch((err) => {
        status.value = 'fail';
        reason.value = err.errName;
	});
};

const addShopOrderSourceRef = (params)=>{
    http.post("/request/?cmd=com.xqxc.api.pigear.biz.account.MerchantAuthService.addShopOrderSourceRef&group=earApp", params).then((res) => {
        status.value = 'success';
	}).catch((err) => {
        status.value = 'fail';
        reason.value = err.errName;
	});
};


const getSignature = ()=>{
    http.post("/request/?cmd=com.xqxc.api.pigear.biz.weixin.WeixinShareService.getPHZHApplet&group=earApp", {}).then((res) => {
        console.log(res);
        wxConfig(res.result);
	}).catch((err) => {
        console.log(err);
	});
};

const wxConfig = (data)=>{
    console.log('wxconfig:',data);
    let appId = data.appId;
    let jsapi_ticket = data.ticket;
    // let timestamp = Date.parse(new Date()) / 1000;
    let timestamp = data.timestamp;
    let nonceStr = data.nonceStr;
    let signature = `jsapi_ticket=${jsapi_ticket}&noncestr=${nonceStr}&timestamp=${timestamp}&url=${location.href.split('#')[0]}`;
    wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId: appId, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: sha1(signature),// 必填，签名
        jsApiList: ['openMiniProgram'], // 必填，需要使用的JS接口列表
        openTagList: [
            'wx-open-launch-weapp'
        ] // 可选，需要使用的开放标签列表，例如['wx-open-launch-weapp']
    });
};

</script>

<style lang="scss" scoped>
.container{
    .success,
    .fail{
        width: 100%;
        height: 80vh;
        padding-top: 3rem;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        .icon{
            width: 2rem;
            img{
                max-width: 100%;
                height: auto;
            }
        }
        .status{
            padding: .5rem;
            line-height: 1.5;
            font-size: .3rem;
            color: #999;
        }
        .desc{
            padding: 0 .5rem;
            line-height: 1.5;
            font-size: .3rem;
            color: #999;
            p{
                padding: .2rem 0;
            }
        }
    }
    .goApp{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 1rem;
        box-sizing: border-box;
        position: absolute;
        bottom: 1rem;
        .mpcode{
            width: 3rem;
            height: 3rem;
            background-color: #FFF;
            padding: .2rem;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: .2rem;
            img{
                width: 100%;
                height: auto;
            }
        }
        .btn{
            width: 100%;
            height: .9rem;
            background: #00a2e8;
            color: #FFF;
            font-size: .3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: .1rem;
        }
    }
}
</style>